import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Header from "../components/header"
import Seo from "../components/seo"

// markup
const BakeryPage = () => {
  return (
    <div>
      <Seo title="BAKERY&CAFE - ベーカリー＆カフェ" />
      <main id="wrap">
        <Header />
        <article className="main-col" role="main">
          <div className="main-wrap">
            <div className="key-visual">
              <div className="main-img">
                <div className="img">
                  <StaticImage 
                    layout="fullWidth"
                    alt="ヒヨリカフェ＆ベーカリーメイン画像"
                    src="../images/bakery/mv.jpg"
                    className="pc"
                  />
                  <StaticImage 
                    layout="fullWidth"
                    alt="ヒヨリカフェ＆ベーカリーメイン画像"
                    src="../images/bakery/mv-sp.jpg"
                    className="sp"
                  />
                  <div className="logo-sp sp">
                    <StaticImage 
                      layout="constrained"
                      alt="ヒヨリベーカリー＆カフェ"
                      src="../images/bakery/logo.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="c-logo-exp l-flex">
              <div className="img logo pc">
                <StaticImage 
                  layout="constrained"
                  alt="ヒヨリベーカリー＆カフェ"
                  src="../images/bakery/logo.svg"
                />
              </div>
              <div className="text">
                <p>大人気「メゾンカイザー」で製法を学んだ職人が、おいしさの秘訣がつまった同製法で毎日焼きたてのパンを提供いたします。<br />オリジナル製粉の小麦粉、最高級の無塩の発酵バターを使い、天然酵母を用いてフランスの伝統的な製法で焼き上げます。<br /><br />メゾンカイザーの代名詞である「ファーメントバタークロワッサン」や「バゲット」はもちろん、「平田牧場金華豚・三元豚」の精肉やハム・ソーセージを使用したオリジナルの惣菜パンも提供いたします。ランチはもちろん、日和山公園でのピクニックにもおすすめです。<br /><br />カフェのパンケーキは、国産の地粉「黄金鶴（こがねつる）」を使用、小麦本来の風味と甘みを感じる一皿に仕上げました。パンケーキやサンドイッチに加え、スーパーフードと呼ばれるキヌアをつかったサラダなどもご提供しています。<br /><br />「NOZY COFFEE」のこだわりの豆をオリジナルのブレンドで味わえるコーヒー、「Mighty Leaf」のオーガニックティーに季節のフルーツを添えたフルーツインティーなどもご用意しており、酒田港を見下ろす開放的な空間、また2階の広々としたスペースでも、のんびりとしたひとときをお過ごしいただけます。</p>
              </div>
            </div>
          </div>
          <section>
            <div className="img">
              <StaticImage 
                layout="constrained"
                alt="ヒヨリベーカリー＆カフェ店内"
                src="../images/bakery/img01.jpg"
              />
            </div>
            <div className="c-pages text sans">
              <h3>ホッとする空間で、<br className="sp" />山と畑の恵みをお召し上がりください。</h3>
              <p>歴史を感じる外観の和館、中に入ると港を一望する大きな窓が印象的な明るく現代的なカフェ空間。<br />お友達とおしゃべりをしながらのティータイム、家族とのランチ、仕事の合間にちょっとコーヒーを飲んで一息ついたり。<br />緊張感から解放されホッとする心と体に優しい時間をご提供いたします。</p>
            </div>
            <div className="c-pages thums l-flex">
              <StaticImage 
                layout="constrained"
                alt="ベーカリーコーヒーカップ"
                src="../images/bakery/thum01.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="パンケーキ"
                src="../images/bakery/thum02.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="パフェ"
                src="../images/bakery/thum03.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="ドリンク"
                src="../images/bakery/thum04.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="接客・サービス"
                src="../images/bakery/thum05.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="ホットサンド"
                src="../images/bakery/thum06.jpg"
              />
            </div>
            <p className="c-pages-caution sans before-btn">※メニューは一例です。 季節や仕入れ状況により変更になる場合がございます。あらかじめご了承ください。</p>
            <div className="c-pages btn">
              <Link className="sans" to='../images/bakery-cafe-menu.pdf'>ヒヨリベーカリー＆カフェ PDF</Link>
              {/* <Link className="sans" to='../images/bakery-cafe-menu_en.pdf'>HIYORI BAKERY & CAFE PDF</Link> */}
            </div>
          </section>
          <section>
            <div className="img">
              <StaticImage 
                layout="constrained"
                alt="ヒヨリベーカリー＆カフェのこだわりのパン"
                src="../images/bakery/img02.jpg"
              />
            </div>
            <div className="c-pages text sans">
              <h3>1800年代の伝統製法で<br className="sp" />小麦本来の香りが楽しめるこだわりのパン</h3>
              <p>大人気店「メゾンカイザー」で修行した職人が、メゾンカイザーと同じ製法で毎日焼きたてのパンをご提供。<br />オリジナル製粉の小麦粉で、特別製法で作られた最高級の無塩の発酵バターを使い、自然界に存在する酵母菌を培養した<br className="pc" />液体天然酵母（ルヴァンリキッド）を用いてフランスの伝統的な製法で焼き上げます。<br />メゾンカイザーの代名詞である「ファーメントバタークロワッサン」や「バゲット」はもちろん、<br className="pc" />「平田牧場金華豚・三元豚」の精肉やハム・ソーセージを使用したオリジナルの惣菜パンも提供いたします。</p>
            </div>
            <div className="c-pages thums l-flex">
              <StaticImage 
                layout="constrained"
                alt="クロワッサン"
                src="../images/bakery/thum20.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="キャラパン"
                src="../images/bakery/thum21.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="菓子パン"
                src="../images/bakery/thum22.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="惣菜パン"
                src="../images/bakery/thum23.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="ウインナーサンド"
                src="../images/bakery/thum24.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="トマトとチーズのパン"
                src="../images/bakery/thum25.jpg"
              />
            </div>
            <p className="c-pages-caution sans before-btn">※メニューは一例です。 季節や仕入れ状況により変更になる場合がございます。あらかじめご了承ください。</p>
            <div className="c-pages btn">
              <Link to='../images/bread.pdf'>定番ベーカリーのご紹介</Link>
            </div>
          </section>
          <section>
            <div className="img">
              <StaticImage 
                layout="constrained"
                alt="店内のセレクトショップ"
                src="../images/bakery/img03.jpg"
              />
            </div>
            <div className="c-pages text sans">
              <h3>ローカルを中心に、<br className="sp" />クラフトにこだわったセレクトショップ</h3>
              <p>山形・庄内を中心にした安心・安全でおいしい地域性商品や、日和山 小幡楼でしか買えない商品もご提供いたします。<br />カフェで味わえる「NOZY COFFEE（ノージーコーヒー）」や「MightyLeaf（マイティリーフ）」もお取り扱いしています。<br />お土産や大切な方へのギフト、自分へのプレゼントにいかがですか。</p>
            </div>
            <div className="c-pages thums l-flex">
              <StaticImage 
                layout="constrained"
                alt="ティーバックやコーヒー"
                src="../images/bakery/thum30.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="エナジーバー"
                src="../images/bakery/thum31.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="地元のお土産"
                src="../images/bakery/thum32.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="地元のおみやげ２"
                src="../images/bakery/thum33.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="ショーウィンドウ"
                src="../images/bakery/thum34.jpg"
              />
              <StaticImage 
                layout="constrained"
                alt="ビール"
                src="../images/bakery/thum35.jpg"
              />
            </div>
            <p className="c-pages-caution sans">※お取り扱い商品は一例です。季節や仕入れ状況により変更になる場合がございます。あらかじめご了承ください。</p>
          </section>
          <footer>
            <div className="c-footer__wrap">
              <ul>
                <li><Link className="menu-item store-info" to="/">日和亭 小幡楼</Link></li>
                <li><Link className="menu-item store-info" to="/bakerycafe/">ヒヨリベーカリー＆カフェ</Link></li>
                <li><Link className="menu-item store-info" to="/hiyoritei/">日和亭</Link></li>
                <li><Link className="menu-item store-info" to="/access/">アクセス</Link></li>
                <li><Link className="menu-item store-info" to="/history/">割烹小幡の歴史</Link></li>
              </ul>
            </div>
            <small className="sans">&copy; 2021 obatarou</small>
          </footer>
        </article>
      </main>
    </div>
  )
}

export default BakeryPage
